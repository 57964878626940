<template>
  <v-bottom-sheet
    v-model="internalShowing"
  >
    <b10-qr-reader
      :showing.sync="showingQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
    <v-sheet
      height="100%"
      class="pa-3"
    >
      <v-form
        @submit.prevent="submit"
      >
        <v-text-field
          v-model="form.q"
          :label="labelBuscar"
          :append-outer-icon="$vuetify.icons.values.qr"
          autofocus
          inputmode="numeric"
          @click:append-outer="clickQR"
        />
        <v-btn
          v-if="busquedaAvanzada"
          text
          block
          small
          color="primary"
          @click="clickBuscarAvanzado"
        >
          <v-icon
            left
            dark
          >
            {{ $vuetify.icons.values.search }}
          </v-icon>
          Búsqueda avanzada
        </v-btn>
      </v-form>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
  props: {
    idparteTrabajo: {
      type: [Number, String],
      required: true,
    },
    idsubsis: {
      type: [Number, String],
      default: '',
    },
    idgrupoChecklistOt: {
      type: [Number, String],
      default: '',
    },
    busquedaAvanzada: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      formValido: true,
      form: {
        q: '',
        idsubsis: null,
      },
      showingQR: false,
      subsis: {},
      grupoChecklistOt: {},
    }
  },
  computed: {
    filtroBuscar () {
      if (this.subsis && this.subsis.subsis && this.subsis.subsis.descripcion) {
        return this.subsis.subsis.descripcion
      } else if (
        this.grupoChecklistOt &&
        this.grupoChecklistOt.grupo_checklist_ot &&
        this.grupoChecklistOt.grupo_checklist_ot.descripcion
      ) {
        return this.grupoChecklistOt.grupo_checklist_ot.descripcion
      } else {
        return ''
      }
    },
    labelBuscar () {
      if (this.filtroBuscar) {
        return `Buscar material afectado (en ${this.filtroBuscar})`
      } else {
        return 'Buscar material afectado'
      }
    },
  },
  watch: {
    showing (newValue) {
      if (newValue) {
        this.$set(this.form, 'q', '')
      }
    },
  },
  async created () {
    if (this.idsubsis) {
      this.subsis = await this.$offline.subsis.row(this.idsubsis)
    } else if (this.idgrupoChecklistOt) {
      this.grupoChecklistOt = await this.$offline.grupoChecklistOt.row(this.idgrupoChecklistOt)
    }
  },
  methods: {
    submit () {
      if (this.form.q) {
        this.internalShowing = false
        this.search(this.form.q)
      }
    },
    clickQR () {
      this.internalShowing = false
      this.showingQR = !this.showingQR
    },
    async decodeQR (value) {
      this.search(value)
    },
    async search (value) {
      const material = await this.$offline.parteTrabajoMatsist.buscarMaterialPorCodigo(
        this.idparteTrabajo, value, this.idsubsis, this.idgrupoChecklistOt
      )
      if (material.length > 0) {
        this.$appRouter.push({
          name: 'offline__material-afectado-view',
          params: {
            idparte_trabajo: this.idparteTrabajo,
            idparte_trabajo_matsist: material[0].parte_trabajo_matsist.idparte_trabajo_matsist,
          }
        })
      } else {
        if (this.filtroBuscar) {
          this.$alert.showSnackbarInfo(
            `No se puede localizar ningún material con el identificador ${value} dentro del material afectado del parte Nº${this.idparteTrabajo} (en ${this.filtroBuscar})`
          )
        } else {
          this.$alert.showSnackbarInfo(
            `No se puede localizar ningún material con el identificador ${value} dentro del material afectado del parte Nº${this.idparteTrabajo}`
          )
        }
      }
    },
    clickBuscarAvanzado () {
      this.$emit('click-buscar-avanzado')
    },
  },
}
</script>
